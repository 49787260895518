import { downloadLoading } from '../store/searchInventory/actionCreator'

const mimeTypes = {
  CSV: 'text/csv',
  XLSX: 'application/vnd.ms-excel',
  PDF: 'application/pdf',
}

export function download(response, dispatch = () => {}, downloadType = 'CSV') {
  let blobString = new Blob([response.data])
  if (downloadType && mimeTypes[downloadType]) {
    blobString = new Blob([response.data], {
      type: mimeTypes[downloadType],
    })
  }
  const url = window.URL.createObjectURL(blobString)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', response.headers.filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  dispatch(downloadLoading(false, downloadType))
}

export function createErrorString(array) {
  let returnString
  if (array.length === 1) {
    returnString =
      'We encountered an error while trying to download ' + array[0]
  } else if (array.length === 2) {
    returnString =
      'We encountered an error while trying to download ' +
      array[0] +
      ' and ' +
      array[1]
  } else {
    returnString =
      'We encountered an error while trying to download ' +
      array.slice(0, -1).join(', ') +
      ', and ' +
      array[array.length - 1]
  }
  return returnString
}
