import * as types from './actionType'
import * as batchService from '../../services/batchScannerService'
import { responseToBatchScanner } from 'mappers/batchMapper'
import { showNotification } from 'store/notification/actionCreator'

export const uploadBatchFile = (file = []) => {
  //   args = Array.from(new Set(args))
  return async (dispatch) => {
    dispatch(fetchDataRequestPending(true))
    return await batchService
      .saveBatch(file)
      .then((itemResult) => {
        dispatch(getBatches(1, 10))
        dispatch(showNotification(true, 'Upload Successful', 'success'))
      })
      .catch((error = {}) => {
        dispatch(fetchDataRequestPending(false))
        dispatch(
          showNotification(
            true,
            'There Has Been An Error While Uploading The Batch'
          )
        )
      })
  }
}

export const getBatches = (
  page = Number(1),
  rowsPerPage = Number(10),
  userIds = [],
  pageRerender = true
) => {
  return async (dispatch) => {
    pageRerender && dispatch(fetchDataRequestPending(true))
    return await batchService
      .getBatches(page, rowsPerPage, userIds)
      .then((itemResult) => {
        let result =
          typeof itemResult?.data === 'string'
            ? JSON.parse(itemResult?.data)
            : itemResult?.data

        dispatch(
          getBatchSuccessful(
            responseToBatchScanner(result?.batch_responses),
            result?.total_count
          )
        )
      })
      .catch((error = {}) => {
        dispatch(fetchDataRequestPending(false))
        dispatch(
          showNotification(
            true,
            'There Has Been An Error While Retrieving Data'
          )
        )
      })
  }
}
export const copyBatch = (selectedIds = []) => {
  return async (dispatch) => {
    return await batchService
      .copyBatches(selectedIds)
      .then((itemResult) => {
        let result =
          typeof itemResult?.data === 'string'
            ? JSON.parse(itemResult?.data)
            : itemResult?.data
        dispatch(copyBatchSuccessful(result))
      })
      .catch((error = {}) => {
        dispatch(fetchDataRequestPending(false))
        dispatch(
          showNotification(
            true,
            'There Has Been An Error While Retrieving Data'
          )
        )
      })
  }
}
export const editBatch = (
  selectedColumn = {},
  rowsPerPage = 10,
  page = 1,
  userIds = []
) => {
  return async (dispatch) => {
    return await batchService
      .editBatches(selectedColumn)
      .then((itemResult) => {
        dispatch(getBatches(page, rowsPerPage, userIds, false))
      })
      .catch((error = {}) => {
        dispatch(fetchDataRequestPending(false))
        dispatch(
          showNotification(
            true,
            'There Has Been An Error While Retrieving Data'
          )
        )
      })
  }
}
export const deleteBatches = (
  selectedData = [],
  rowsPerPage = 10,
  page = 1,
  userIds = []
) => {
  return async (dispatch) => {
    dispatch(fetchDataRequestPending(true))
    return await batchService
      .deleteBatch(selectedData)
      .then((itemResult) => {
        dispatch(getBatches(page, rowsPerPage, userIds))
        dispatch(showNotification(true, 'Batch Deleted Succesfully', 'success'))
      })
      .catch((error = {}) => {
        dispatch(fetchDataRequestPending(false))
        dispatch(
          showNotification(
            true,
            'There Has Been An Error While Retrieving Data'
          )
        )
      })
  }
}
export function getBatchSuccessful(data = [], totalCount = Number(0)) {
  return {
    type: types.GET_BATCH_SUCCESSFUL,
    payload: { data: data, totalCount: totalCount },
  }
}

export function copyBatchSuccessful(data = []) {
  return {
    type: types.COPY_BATCH_SUCCESSFUL,
    payload: { data: data },
  }
}

export function fetchDataFailure() {
  return {
    type: types.FETCH_DATA_FAILURE,
    payload: { fetchDataError: 'Failed to fetch data' },
  }
}
export function deleteBatchConfirm() {
  return {
    type: types.DELETE_BATCH_CONFIRM,
  }
}

export function fetchDataRequestPending(pending = false) {
  return {
    type: types.FETCH_DATA_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchDataSuccess(data = []) {
  return {
    type: types.FETCH_DATA_SUCCESS,
    payload: { data: [...data] },
  }
}
