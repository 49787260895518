import { MuiThemeProvider } from '@material-ui/core/styles'
import { AuthProvider } from '@praxis/component-auth'
import { unstable_HistoryRouter as Router } from 'react-router-dom'
import muiTheme from './config/themeConfig'
import apiConfig from './apiConfig'
import Layout from './components/Layout/Layout'
import { CssBaseline } from '@material-ui/core'
import Interceptor from './services/httpInterceptor'
import history from 'components/Router/history'
import ErrorBoundary from 'components/ErrorBoundary'
import { EnvProvider } from '@praxis/component-runtime-env'
import { AnalyticsProvider } from '@praxis/component-analytics'
import LayoutItemHub from 'components/Layout/LayoutItemHub'
import { itemCommonUtilis } from 'item-component'

const App = () => (
  <EnvProvider
    configPath={
      process.env.NODE_ENV === 'development'
        ? '/config.json'
        : '/app-environment'
    }
  >
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <AuthProvider {...apiConfig.auth}>
        <Router basename={itemCommonUtilis.findAppPath()} history={history}>
          <AnalyticsProvider
            eventManagerConfiguration={apiConfig.analytics}
            trackLocations
          >
            <Interceptor>
              <ErrorBoundary>
                {process.env.REACT_APP_ENV.indexOf('peg_ih_pol') !== -1 ? (
                  <LayoutItemHub />
                ) : (
                  <Layout />
                )}
              </ErrorBoundary>
            </Interceptor>
          </AnalyticsProvider>
        </Router>
      </AuthProvider>
    </MuiThemeProvider>
  </EnvProvider>
)

export default App
