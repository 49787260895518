import axios from 'axios'
import apiConfig from '../apiConfig'

export async function saveBatch(file = '') {
  let formdata = new window.FormData()
  formdata.append('file', file)
  const {
    batches: { url = '' },
    apiKey = '',
  } = apiConfig
  return await axios.post(url, formdata, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
    params: {
      key: apiKey,
    },
    responseType: 'blob',
  })
}

export async function getBatches(page, rowsPerPage, userIds) {
  const {
    batches: { url = '' },
    apiKey = '',
  } = apiConfig
  return await axios.get(url, {
    headers: {
      'Content-Type': `application/json`,
    },
    params: {
      key: apiKey,
      page: page,
      per_page: rowsPerPage,
      user_ids: userIds.join(),
    },
    responseType: 'application/json',
  })
}

export async function copyBatches(ids = []) {
  const {
    batches: { url = '' },
    apiKey = '',
  } = apiConfig
  return await axios.get(url + 'imported_ids', {
    headers: {
      'Content-Type': `application/json`,
    },
    params: {
      key: apiKey,
      batch_ids: ids.map((idInstance) => idInstance?.batch_id).join(),
    },
    responseType: 'application/json',
  })
}

export async function editBatches(selectedData = {}) {
  const {
    batches: { url = '' },
    apiKey = '',
  } = apiConfig
  return await axios.put(
    url + selectedData?.batch_id,
    {
      headers: {
        'Content-Type': `application/json`,
      },  
    },
    {
      params: {
        key: apiKey,
        batch_ids: selectedData?.batch_name_actual.toString(),
        batch_name: selectedData?.batch_name_actual.toString(),
      },
      responseType: 'application/json',
    }
  )
}

export async function deleteBatch(selectedData = []) {
  const {
    batches: { url = '' },
    apiKey = '',
  } = apiConfig
  return await axios.delete(url, {
    headers: {
      'Content-Type': `application/json`,
    },
    params: {
      key: apiKey,
      batch_ids: selectedData.map((idInstance) => idInstance?.batch_id).join(),
    },
    responseType: 'application/json',
  })
}
